import { Link, graphql } from "gatsby"
import React from "react"
import Layout from "../components/layout.js"
import { menuPageLink, chapterContainer, chapterLink} from '../components/layout.module.css'



export default function ChapterTemplate({data}) {
    return (
    <Layout pageTitle={data.mdx.frontmatter.chapterTitle}>
        <div className={chapterContainer}>
        {data.sectionsInGroups.group.map(node => (
          <div className={chapterLink}>
            {node.fieldValue}
                {node.nodes.map(node => (
                  <p className={menuPageLink}>
                     <Link to={"/" + node.frontmatter.slug}>
                       {node.frontmatter.title}</Link>
                  </p>
                ))
                }
          </div>
        ))
      }
      </div>
        </Layout>
  )
}

export const query = graphql`
query ChapterInfo($titleChapter: String) {
    mdx (frontmatter: {chapterTitle: {eq: $titleChapter}}) {
        frontmatter {
          section
          sectionTitle
          chapterTitle
          title
        }
      }
      sectionsInGroups: allMdx(
        filter: {frontmatter: {chapterTitle: {eq: $titleChapter}}}) {
        group(field: frontmatter___sectionTitle) {
          fieldValue
          nodes {
            frontmatter {
              title
              slug
            }
          }
        }
      }
}
`